import withRoot from "../utils/withRoot";
import React from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import qs from "qs";
import Page from "../components/Page";
import SEO from "../components/SEO";

const useStyles = makeStyles(theme => ({
  listroot: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(2),
  },
  listinline: {
    display: "inline",
    // width: 300,
  },
  highlighter: {
    width: "auto",
  },
}));

class Checkout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      age: 1,
      goodid: 1,
      submitted: false,
      userinfo: {},
      orderconfig:{},
      inputDisable: false,
      status: "",
    };
  }

  onBridgeReady = (orderconfig)=>{
    WeixinJSBridge.invoke(
       'getBrandWCPayRequest', {
          "appId":"wxa680b9b884630fab",     //公众号ID，由商户传入     
          "timeStamp":orderconfig.timestamp,         //时间戳，自1970年以来的秒数     
          "nonceStr":orderconfig.nonceStr, //随机串     
          "package":orderconfig.package,     
          "signType":orderconfig.signType,         //微信签名方式：     
          "paySign":orderconfig.paySign //微信签名 
       },
       function(res){
       if(res.err_msg == "get_brand_wcpay_request:ok" ){
       // 使用以上方式判断前端返回,微信团队郑重提示：
             //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
             this.setState({
               status: "完成支付"
             })
       }else{
          this.setState({
            status:res.err_msg
          })
       } 
    }); 
 }

  componentDidMount = () => {
    const { location } = this.props;
    console.log("localtion is: ", location);
    let params = qs.parse(location.search);

    if (params["?code"] !== undefined) {
      let code = params["?code"];
      console.log("code is : ",code);
      fetch("https://kids-abc.cn/msg/openid?code=" + code)
        .then(resp => resp.json())
        .then(resp => {
          console.log("user info:",resp);
          this.setState({
            userinfo: resp,
          });
        })
        .catch(e => {
          console.log(e);
          console.log("获取access token失败");
        });
    } else {
      this.setState({
        age: 1,
        content: "请从微信公众号点开此链接",
        inputDisable: false,
      });
    }
  };

  handlePay = () => {
    let { userinfo, goodid } = this.state;
    let form = {
      good_id:parseInt(goodid),
      open_id:userinfo.openid,
    }
    console.log("js config req:",form)
    fetch("https://kids-abc.cn/msg/orders/payjsconfig", {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      body: JSON.stringify(form),
      headers: new Headers({ "content-type": "application/json" }),
    })
      .then(resp => {
        if (resp.ok) {
          console.log(resp.status, resp.statusText);
          return resp.json();
        } else {
          return Promise.reject({ error: "请求失败" });
        }
      })
      .then(resp => {
        console.log("pre order info",resp);
        this.setState({
          status: "提交成功",
          orderconfig:resp,
        });
        this.onBridgeReady(resp)
      })
      .catch(error => {
        this.setState({
          status: "提交失败",
          content: "",
        });
        console.log(error);
        console.log("发送失败!");
      });
  };


  render() {
    const { classes } = this.props;

    return (
      <Page title="购买课程">
        <SEO title="购买课程">
          <meta content="购买课程" name="description" />
        </SEO>
        <Typography id="discrete-slider" gutterBottom>
          课程名称
        </Typography>
        <TextField
          id="standard-full-width"
          multiline
          rows={1}
          label="商品ID"
          style={{ margin: 8 }}
          placeholder={this.state.goodid}
          value={this.state.content}
          disabled={this.state.inputDisable}
          onChange={e => {
            this.setState({ goodid: e.target.value });
          }}
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={this.handlePay}
        >
          支付
        </Button>
        <Typography>{this.state.status}</Typography>
      </Page>
    );
  }
}

export default withRoot(Checkout);
